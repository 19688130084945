<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "App",
  components: {},
  setup() {
    onMounted(() => {
      const img = new Image();
      img.src = new URL('@/assets/bookcase.webp', import.meta.url).href;
    });
  }  
});
</script>

<template>
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("@/assets/bookcase.webp");
  background-repeat: repeat-y;
  background-size: 100%;
}

div#app {
  flex: 1;
}
</style>
